import Vue from "vue";
import Router from "vue-router";
import Firebase from "./config/firebase";
import store from "./store";

Vue.use(Router);

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/square/:id",
      name: "square",
      meta: {
        requiresAuth: true,
      },
      component: () => import("@/views/Square.vue"),
    },

    //DGFT
    {
      path: "/dgft",
      name: "dgft",
      meta: {
        requiresAuth: true,
      },
      component: () => import("@/views/Dgft.vue"),
    },

    {
      path: "/error",
      name: "error",
      component: () => import("@/views/Error.vue"),
    },
    {
      path: "/welcome",
      name: "welcome_to_login",
      component: () => import("@/components/Login.vue"),
    },
    {
      path: "/sct/:id",
      name: "sct",
      component: () => import("@/components/Sct.vue"),
    },
    {
      path: "/welcome/:id",
      name: "welcome",
      component: () => import("@/components/Welcome.vue"),
    },
    {
      path: "/login",
      name: "login",
      component: () => import("@/components/Login.vue"),
    },
    {
      path: "/signup/:id",
      name: "signup",
      component: () => import("./components/Signup.vue"),
    },
    {
      path: "/phoneAuthenticationInput/:id",
      name: "phoneAuthenticationInput",
      component: () => import("./views/phoneAuthenticationInput.vue"),
    },

    {
      path: "/signup/",
      name: "search",
      component: () => import("./components/Search.vue"),
    },
    {
      meta: { requiresAuth: true },
      path: "/patient_pri/:id",
      name: "patient_pri",
      component: () => import("./components/PatientPri.vue"),
    },
    {
      meta: { requiresAuth: true },
      path: "/patient_regster/:id",
      name: "patient_regster",
      component: () => import("./components/PatientRegster.vue"),
    },
    {
      meta: { requiresAuth: true },
      path: "/patient_form_regster/:id",
      name: "patient_form_regster",
      component: () => import("./components/PatientFormRegster.vue"),
    },
    {
      path: "/checkout/:id",
      name: "checkout",
      component: () => import("./views/Checkout.vue"),
      meta: { requiresAuth: true },
    },

    {
      path: "/twofucter/:id",
      name: "twofucter",
      component: () => import("@/components/TwoFucter.vue"),
      meta: { requiresAuth: true },
    },

    {
      path: "/",
      component: () => import("@/layouts/base/Index.vue"),
      meta: { requiresAuth: true },
      children: [
        {
          path: "/qa",
          name: "qa",
          component: () => import("@/views/QA.vue"),
        },
        {
          path: "/notice",
          name: "notice",
          component: () => import("@/views/Notice.vue"),
        },
        {
          path: "/rule",
          name: "rule",
          component: () => import("@/views/Rule.vue"),
        },
        {
          path: "/clinic_list/",
          name: "clinic_list",
          component: () => import("@/views/Home.vue"),
        },
        {
          path: "/",
          name: "home",
          component: () => import("@/views/Home.vue"),
        },

        {
          path: "/history/",
          name: "history",
          component: () =>
            import(
              /* webpackChunkName: "shop price list" */ "@/components/History.vue"
            ),
        },
        {
          path: "/priscription_list/:id",
          name: "priscription_list",
          component: () => import("@/views/PriscriptionList.vue"),
        },
        {
          path: "/prescription/:id",
          name: "prescription",
          meta: {
            requiresAuth: true,
          },
          component: () => import("@/components/Prescription.vue"),
        },
        {
          path: "/about",
          name: "about",
          props: true,
          component: () =>
            import(/* webpackChunkName: "about" */ "@/views/About.vue"),
        },
        {
          path: "/printservice",
          name: "printservice",
          component: () =>
            import(/* webpackChunkName: "about" */ "./views/Printservice.vue"),
        },
        {
          path: "/order/detail/:id",
          name: "detail",
          component: () =>
            import(
              /* webpackChunkName: "shop price list" */ "./components/order/detail.vue"
            ),
        },
        {
          path: "/paid/:id",
          name: "paid",
          component: () =>
            import(
              /* webpackChunkName: "shop price list" */ "@/components/Paid.vue"
            ),
        },
        {
          path: "/gmo_error/:id",
          name: "gmo_error",
          component: () =>
            import(/* webpackChunkName: "gmo error" */ "./views/GmoError.vue"),
        },

        {
          path: "/dgft_error/:id",
          name: "dgft_error",
          component: () =>
            import(/* webpackChunkName: "gmo error" */ "./views/DGFTError.vue"),
        },
      ],
    },
  ],
});

router.beforeEach((to, from, next) => {
  Firebase.onAuth();
  let currentUserStatus = store.getters["isSignedIn"];
  let requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  if (!requiresAuth) {
    next();
  } else if (requiresAuth && !currentUserStatus) {
    next("/login");
  } else {
    next();
  }
});

export default router;
