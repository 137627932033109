<template>
  <v-fade-transition mode="out-in">
    <router-view />
  </v-fade-transition>
</template>

<script>
export default {
  name: "App",
  mounted: async function () {
    await this.importScript();
  },
  methods: {
    importScript: async function () {
      const script = document.createElement("script");
      //"https://web.squarecdn.com/v1/square.js"
      //"https://sandbox.web.squarecdn.com/v1/square.js"
      await script.setAttribute(
        "src",
        "https://web.squarecdn.com/v1/square.js"
      );
      script.setAttribute("id", "script_id");
      document.head.appendChild(script);
    },
  },
};
</script>

<style lang="scss"></style>
