// ~store/index.js
import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
Vue.use(Vuex);

// import from step 1
import { initFirebase } from "../config/firebase.js";
// import from step 3 (below)

// include as PLUGIN in your vuex store
// please note that "myModule" should ONLY be passed via the plugin
const storeData = {
  plugins: [createPersistedState({ storage: window.sessionStorage })],
  // ... your other store data
  state: {
    clinic: null,
    patient: null,
    authuser: {},
    status: false,
    order: {},
    merchandises: {
      l_merchandise: null,
      r_merchandise: null,
    },
  },
  mutations: {
    onAuthStateChanged(state, authuser) {
      state.authuser = authuser; //firebaseが返したユーザー情報
    },
    onUserStatusChanged(state, status) {
      state.status = status; //ログインしてるかどうか true or false
    },
  },
  getters: {
    user(state) {
      return state.authuser;
    },
    isSignedIn(state) {
      return state.status;
    },
  },
};

// initialise Vuex
const store = new Vuex.Store(storeData);

// initFirebase
initFirebase().catch((error) => {
  // take user to a page stating an error occurred
  // (might be a connection error, or the app is open in another tab)
  console.error("Error initFirebase:", error);
});

export default store;
