import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import store from "./store";
Vue.config.productionTip = false;

// Import the functions you need from the SDKs you need
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
import VueI18n from "vue-i18n";

import i18n from "./i18n";

Vue.use(VueI18n);

new Vue({
  render: (h) => h(App),
  store,
  vuetify,
  router,
  components: { App },
  i18n,
  template: "<App/>",
}).$mount("#app");
