// ~config/firebase.js
//import * as Firebase from 'firebase/app'
import "firebase/firestore";
import "firebase/storage";
import "firebase/functions";
import "firebase/analytics";
import Firebase from "firebase/app";
import "@firebase/auth";

import store from "../store";
import router from "../router";
// Populate process.env with values from .env file
require("dotenv").config();

export default {
  async login(mailaddress, password) {
    const status = await Firebase.auth()
      .signInWithEmailAndPassword(mailaddress, password)
      .then((result) => {
        // 成功時の処理
        var user = result.user ? result.user : {};
        store.commit("onAuthStateChanged", user);
        store.commit("onUserStatusChanged", user.uid ? true : false);
        return result;
      })
      .catch((error) => {
        // 失敗時の処理
        return error;
      });
    return status;
  },
  async logout() {
    await Firebase.auth()
      .signOut()
      .then(() => {
        // ログイン画面に遷移
        router.push({ path: "/login" });
      })
      .catch(() => {
        alert("ログアウト失敗");
      });
  },
  async adduser(user_uid, mailaddress, phone) {
    await Firebase.firestore().collection("Users").doc(user_uid).set(
      {
        isAdmin: false,
        isClinic: false,
        isSaler: false,
        isPatient: true,
        email: mailaddress,
        phone: phone,
        clinics: [],
      },
      { merge: true }
    );
  },
  async signup(mailaddress, password, phone) {
    // 新規ユーザーを登録
    await Firebase.auth()
      .createUserWithEmailAndPassword(mailaddress, password)
      .then((result) => {
        this.adduser(result.user.uid, mailaddress, phone);
        var user = result.user ? result.user : {};
        store.commit("onAuthStateChanged", user);
        store.commit("onUserStatusChanged", user.uid ? true : false);
      });
  },
  onAuth() {
    Firebase.auth().onAuthStateChanged((user) => {
      user = user ? user : {};
      store.commit("onAuthStateChanged", user);
      store.commit("onUserStatusChanged", user.uid ? true : false);
    });
  },
  async upload(file, serviceID) {
    const imageURL = `tmp/${serviceID}`;
    return await Firebase.storage()
      .ref()
      .child(imageURL)
      .put(file, function (error) {
        if (error) {
          // The write failed...
          alert("画像のアップロードに失敗しました");
          console.log(error);
        } else {
          console.log("Image file saved successfully!");
        }
      });
  },
};

function initFirebase() {
  Firebase.initializeApp({
    apiKey: "AIzaSyCZlKAVCKBS-jZSBcI7pAlYSKljJMW0Hbg",
    authDomain: "clinicdx.firebaseapp.com",
    projectId: "clinicdx",
    storageBucket: "clinicdx.appspot.com",
    messagingSenderId: "739393132090",
    appId: "1:739393132090:web:46d3cbbb2682806a62c703",
    measurementId: "G-FEL6QJW5K0",
  });

  Firebase.auth().languageCode = "jp";
  Firebase.auth().setPersistence(Firebase.auth.Auth.Persistence.SESSION);

  // Initialize Analytics and get a reference to the service
  //const analytfirenaseics = Firebase.analytics();
  Firebase.analytics().logEvent("notification_received");

  let useEmulator = false;
  if (useEmulator) {
    if (process.env.NODE_ENV != "production") {
      // NOTE: do NOT put this in production.
      // Cloud Functions is able
      Firebase.firestore().settings({
        experimentalForceLongPolling: true,
        ssl: false,
        merge: true,
      }); // 既存の設定とマージするオプション });
      //接続先指定
      Firebase.auth().useEmulator("http://localhost:9099/");
      Firebase.storage().useEmulator("localhost", 9199);
      Firebase.firestore().useEmulator("localhost", 8081);

      const functions = Firebase.app().functions("asia-northeast1");
      functions.useEmulator("localhost", 5001);
    }
    if (process.env.VUE_APP_EMULATE) {
      Firebase.firestore().settings({ experimentalForceLongPolling: true });
      //接続先指定
      Firebase.auth().useEmulator("http://localhost:9099/");
      Firebase.storage().useEmulator("localhost", 9199);
      Firebase.firestore().useEmulator("localhost", 8081);
      Firebase.functions().useEmulator("localhost", 5001);
    }
  }

  return new Promise((resolve, reject) => {
    Firebase.firestore()
      .enablePersistence({ synchronizeTabs: true })
      .then(resolve)
      .catch((err) => {
        if (err.code === "failed-precondition") {
          reject(err);
          // Multiple tabs open, persistence can only be
          // enabled in one tab at a a time.
        } else if (err.code === "unimplemented") {
          reject(err);
          // The current browser does not support all of
          // the features required to enable persistence
        }
      });
  });
}

export { Firebase, initFirebase };
